<template>
  <div
    class="logo-component-flexbox flex justify-center items-center md:items-start items-center mt-12"
  >
    <div
      class="logo-component-container border rounded-md border-gray-300 py-12 px-6 flex flex-col items-center"
    >
      <span
        class="text-2xl text-gray-700 font-light mb-6 w-full login-form-width2 text-center"
      >
        Ingresa tu nueva contraseña
      </span>
      <div class="login-form-container">
        <div class="login-form">
          <transition name="fadeHeight" mode="out-in">
            <alert-box
              v-if="
                errorLogin != '' ||
                  (Array.isArray(errorLogin) && errorLogin.length != 0)
              "
              class="login-alert-box"
              type="danger"
              :title="''"
              :errors="errorLogin"
            />
          </transition>
          <transition name="fadeHeight" mode="out-in">
            <alert-box
              v-if="
                successLogin != '' ||
                  (Array.isArray(successLogin) && successLogin.length != 0)
              "
              class="login-alert-box"
              type="success"
              :title="''"
              :errors="successLogin"
            />
          </transition>
          <div class="w-full flex flex-col justify-center items-start">
            <span class="text-sm text-gray-600"
              >Dirección de correo electrónico</span
            >
            <input
              v-model="form.email"
              name="email"
              type="email"
              autocomplete="email"
              required
              :class="
                errorEmailLogin
                  ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                  : 'border'
              "
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Correo electrónico"
            />
          </div>
          <div class="w-full flex flex-col justify-center items-start">
            <span class="flex flex-row justify-between w-full">
              <span class="text-sm text-gray-600">Contraseña</span>
            </span>
            <input
              v-model="form.password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Contraseña"
            />
          </div>
          <div class="w-full flex flex-col justify-center items-start">
            <span class="flex flex-row justify-between w-full">
              <span class="text-sm text-gray-600">Confirmar contraseña</span>
            </span>
            <input
              v-model="form.confirm_password"
              name="confirm-password"
              type="password"
              autocomplete="confirm-password"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Confirmar contraseña"
              @keyup.enter="submit()"
            />
          </div>
          <div class="w-full flex flex-row justify-start items-center mb-4">
            <router-link
              class="text-sm cursor-pointer underline text-green-600 hover:text-green-800"
              to="/"
              >¿Ya recordaste tu contraseña?</router-link
            >
          </div>
          <div class="w-full my-2">
            <button-component
              @whenClick="submit()"
              :disabled="
                errorLogin.length !== 0 ||
                  !canSubmitLogin ||
                  successLogin.length !== 0 ||
                  errorEmailLogin ||
                  form.email === ''
              "
              :loading="!canSubmitLogin"
            >
              <strong>Cambiar contraseña</strong>
            </button-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AlertBox from '@/components/form/AlertBox.vue';
import Button from '@/components/form/Button.vue';

export default {
  name: 'RestorePasswordToken',
  components: {
    'button-component': Button,
    'alert-box': AlertBox,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        confirm_password: '',
      },
      errorEmailLogin: false,
      errorLogin: [],
      successLogin: [],
      canSubmitLogin: true,
      done: false,
    };
  },
  methods: {
    ...mapActions('authentication', ['restoreToken']),
    validateEmail: email => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    submit() {
      if (!this.canSubmitLogin) {
        return;
      }
      // Validation
      this.setErrorLogin();
      if (!this.validateEmail(this.form.email)) {
        this.setErrorLogin('Correo no válido!');
        return;
      }

      if (this.form.password !== this.form.confirm_password) {
        this.setErrorLogin('Las contraseñas no coinciden');
        return;
      }

      // Login Call
      this.canSubmitLogin = false;
      this.restoreToken({
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.confirm_password,
        token: this.$route.params.token,
      })
        .then(() => {
          this.setSuccessLogin('La contraseña ha sido actualizada!');
          this.done = true;
        })
        .catch(e => {
          console.log(e);
          this.setErrorLogin('No se pudo actualizar la contraseña');
        })
        .finally(() => {
          this.canSubmitLogin = true;
        });
    },
    setErrorLogin(error) {
      if (!error) {
        this.errorLogin = [];
      } else {
        this.errorLogin.push(error);
      }
    },
    setSuccessLogin(success) {
      if (!success) {
        this.successLogin = [];
      } else {
        this.successLogin.push(success);
      }
    },
  },
  watch: {
    'form.email'() {
      if (this.form.email != '') {
        this.errorEmailLogin = !this.validateEmail(this.form.email);
      } else {
        this.errorEmailLogin = false;
      }
    },
  },
};
</script>

<style scoped>
.logo-component-flexbox {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 900px) {
  .logo-component-flexbox {
    flex-direction: column;
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-flexbox {
    flex-direction: row;
  }
}

@media only screen and (max-width: 900px) {
  .logo-component-container-divider {
    width: 80%;
    border: 1px solid rgb(223 223 223 / 30%);
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-container-divider {
    height: 500px;
    border: 1px solid rgb(223 223 223 / 30%);
    margin-left: 25px;
    margin-right: 25px;
  }
}

.logo-component-container {
}
@media only screen and (max-width: 900px) {
  .logo-component-container:nth-child(2) {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-container {
  }
}

.login-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-link {
  cursor: pointer;
  color: #111827;
  text-decoration: underline;
}
.login-link:not(:last-child) {
  margin-right: 1em;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form-container {
    min-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form-container {
    min-width: 500px;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form {
    max-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form {
    max-width: 500px;
  }
}
.login-form-width {
  max-width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form-width2 {
    max-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form-width2 {
    max-width: 500px;
  }
}
.logo-image {
  max-height: 80px;
  margin-bottom: 1em;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.login-remember .login-checkbox {
  width: 50%;
}
.login-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-forgot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  text-align: right;
}
@media only screen and (max-width: 900px) {
  .login-forgot {
    font-size: 12px;
  }
}
@media only screen and (min-width: 901px) {
  .login-forgot {
    font-size: 14px;
  }
}

.login-alert-box {
  margin-bottom: 1em;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
